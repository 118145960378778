.bl-partnerlist {
    .items {
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        gap:0;
        .item {
            padding: calc(0.5 * var(--layoutgridgap));
            box-sizing: border-box;
            @include breakpoint(large) {
                width:25%;
            }
            @include breakpoint(medium down) {
                width:50%;
            }
        }

        .logo {
            @include w100bl;
            aspect-ratio: 5 / 3;

            object-fit: contain;
            object-position: center center;
        }
    }
}

