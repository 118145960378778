.bl-accordion {
    @include breakpoint(large) {
        @include grid12;
        .linner {
            grid-column:3 / span 8;
        }
    }

    .accitem {
        &:first-child {
            border-top:1px solid $kkbton02;
        }
        border-bottom:1px solid $kkbton02;

        .summary {
            display:flex;
            justify-content: space-between;
            align-items: center;

            cursor: pointer;

            margin-top:0.5em;
            margin-bottom:0.5em;

            .title {
                margin-bottom: 0;
            }
            .icon {
                @include rem-calc-sl(height, 40, 0.5);
                display: block;
                transition: transform 0.2s;

                color:$kkhauptrot;
            }
        }
        .details {
            display:none;

            margin-top: 1em;
            margin-bottom: 0.75em;

            p {
                margin-bottom: 0;
            }
            p + p {
                margin-top: 0.5em;
            }

            a {
                color:$kkbton01;
                @include text-decoration-underline;
            }
        }

        &.expanded {
            .icon {
                transform: rotate(45deg);
            }
        }
    }
}

//typo
.bl-accordion {

}