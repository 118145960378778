#offcanvasmenu {
    background-color: #000;
    color:#fff;

    position: fixed;
    right: 0;
    top: 0;
    width: auto;
    height:100%;

    overflow: auto;
    overscroll-behavior: contain;

    z-index: 50;

    box-sizing: border-box;
    padding-top:rem-calc(120);
    padding-bottom:rem-calc(120);
    @include rem-calc-sl(padding-left, 90, 0.5);
    @include rem-calc-sl(padding-right, 90, 0.5);
}

#offcanvasmenu {
    transition:transform .4s;
}
#offcanvasmenu:not(.visible) {
    pointer-events: none;
    transform:translateX(100%);
}

#hamburger-offcanvasmenu {
    position:fixed;
    @include rem-calc-sl(top, 40);
    @include rem-calc-sl(right, 48);
    z-index: 200;
    background-color: white;
    padding: rem-calc(14);
    
    transition:background-color .2s;
    body.offcanvasmenuvisible & {
        background-color: transparent;
        --hbnormal:white;
        --hbhover:white;
    }
}

//override default styles
#offcanvasmenu {
    .verticallistmenu {
        ul {
            .level1 + .level1 {
                margin-top:1.25em;
            }
        }
    }
}

//typo
#offcanvasmenu {
    @include font-size-pt(36*0.75, 36);
    font-weight: 700;
    line-height: math.div(48, 36);
}