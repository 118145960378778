.bl-forminfoabend {
    --textcoloroverride:initial; //delete value, back to "not set"
    color:$textcolor;

    a {
        color:$kkbton01;
    }

    @include breakpoint(large) {
        @include grid12;
    }
    .linner {
        grid-column: 3 / span 8;
        background-color: #fff;

        @include rem-calc-sl(padding-left, 88, 0.5);
        @include rem-calc-sl(padding-right, 88, 0.5);
        @include rem-calc-sl(padding-top, 50, 0.5);
        @include rem-calc-sl(padding-bottom, 100, 0.5);
    }

    .ctrbuttons {
        margin-top:1.5em;
    }

    .formfields {
        @include breakpoint(large) {
            @include grid;
            grid-template-columns: repeat(2, 1fr);
            gap:calc(var(--layoutgridgap) / 2) var(--layoutgridgap);

            .sizespan2 {
                grid-column-end: span 2;
            }
        }
        @include breakpoint(medium down) {
            display: flex;
            flex-direction: column;
            gap:calc(var(--layoutgridgap) / 2);
        }
    }

    //Placeholder logic
    .formfields {
        input, textarea, select {
            background-color: $kkbton03;
            border:none;
            font:inherit;
            padding:1.1em 1em 0.4em 1em;

            width: 100%;
            box-sizing: border-box;

            &:focus {
                border-bottom:1px solid $kkhauptrot;
                outline:none;
            }

            &::placeholder {
                color: transparent;
            }
        }
        select {
            appearance: none;
            border-radius:0;
            padding-top:0.75em;
            padding-bottom:0.75em;

            background-image: url("data:image/svg+xml,%3Csvg width='19' height='10' viewBox='0 0 19 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.01472 0.758719L9.5 9.244L17.9853 0.758719' stroke='%23AF0F2D'/%3E%3C/svg%3E");
            background-size:rem-calc(16);
            background-repeat: no-repeat;
            background-position: 95% 55%;
        }
        input:focus, select:focus {
            margin-bottom: -1px;
        }
        textarea {
            height:10em;
        }
        
        .ctrplhfield {
            position: relative;
            
            label {
                position: absolute;
                left:1em;
                top:0.8em;
                
                pointer-events: none;

                transition: all .2s;
                transform-origin: left top;
            }

            &:focus-within,
            &:not(:has(input:placeholder-shown)):not(:has(textarea:placeholder-shown))
            {
                label {
                    top:0.1em;
                    transform: scale(0.75) translateY(0);
                    color:$kkhauptrot;
                }
            }
        }
    }
}

//typo
.bl-forminfoabend {
    .formfields {
        @include font-size-pt(17, 17);
        font-weight: 400;
        line-height: math.div(24, 17);
    }

    ul {
        @include bulletlist;
    }
}