.wconstrain {
    margin-left: auto;
    margin-right: auto;

    max-width: calc(100% - var(--lrmargin)*2);
    /*
    @include breakpoint(small down) {
        max-width: calc(100% - 3rem);
    }
    */

    &.w_m {
        width: rem-calc(1312);
    }

    &.w_l {
        @include breakpoint(large) {
            width: rem-calc(1580);
        }
    }
    /*
    &.w_xl {
        @include breakpoint(large) {
            width:calc(100% - 7.5rem);
        }
    }
    */
    &.w_full {
        max-width: none;
    }

    /*
    @media (min-width:1300px) {
        &.w_l {
            width: calc(100% - 24rem);
        }
        &.w_m {
            //https://stackoverflow.com/questions/62280339/css-min-function-to-sass
            --temp0: calc(100% - 24rem);
            width: min(70rem, var(--temp0));
        }
    }
    */
}
