.bl-textwithimage {
    .colimage img {
        @include w100bl;
    }

    @include breakpoint(large) {
        .cols {
            @include grid;
            align-items: start;
            @include rem-calc-sl(gap, 160, 0.5);
        }

        &.imgposr {
            .cols {
                grid-template-columns: 50fr 64fr;
            }
            .coltext {
                order: 1;
            }
            .colimage {
                order: 2;
            }
        }
        &.imgposl {
            .cols {
                grid-template-columns: 64fr 50fr;
            }
            .colimage {
                order: 1;
            }
            .coltext {
                order: 2;
            }
        }
    }
    @include breakpoint(medium down) {
        .coltext {
            margin-top:1em;
        }
    }
}
.bl-textwithimage.layout1 {
    .coltext {
        border:1px solid $white;
        padding:1.5em;
    }

    @include breakpoint(large) {
        &.imgposr {
            .colimage {
                margin-top:4em;
            }
        }
        &.imgposl {
            .coltext {
                margin-top:4em;
            }
        }
    }
}
.bl-textwithimage.layout2 {
    @include breakpoint(large) {
        .cols {
            @include rem-calc-sl(gap, 60, 0.5);
        }

        &.imgposr {
            .cols {
                grid-template-columns: 640fr 745fr;
            }
        }
        &.imgposl {
            .cols {
                grid-template-columns: 745fr 640fr;
            }
        }
    }
}

