$black:#000;
$white:#fff;

$textcolor:#fff;
$footertext:#fff;

$gray01: #BEBEBE;
$gray02: #808080;

//TODO: entfernen. achtung: kompilierfehler bei dependencies.
                $kkhauptrot:#A61830;
                $kkrot:#E60037;
                $kkdunkelrot:#3A0B13;
                $kkbton01:#A89B92;
                $kkbton02:#E1CDBE; //Vorgehen scrollbar track
                $kkbton03: #F5EEE6;


/*
@mixin borderradius1 {
    //images
    border-radius: 2rem;
}
@mixin borderradius2 {
    //button
    border-radius: 0.5rem;
}
*/
$buttonbg:#fff;
$buttonfg: #000;
$buttonbg-hover:#000;
$buttonfg-hover: #fff;

:root {
    @include rem-calc-sl(--lrmargin, 32, 0.5);
    @include rem-calc-sl(--layoutgridgap, 60, 0.5);
}


