.bl-herohome {
    position: relative;

    height:100vh;
    height:100lvh;

    .ctrimage {
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        @include breakpoint(large) {
            width:55vw;
            background-color: $gray01;
            clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
        }
        @include breakpoint(medium down) {
            left:0;
        }
        img {
            @include absposfull;
            object-fit: cover;
            object-position: center center;
        }
    }
    
    .ctrtext {
        position: absolute;
        left:0;
        right:0;
        @include rem-calc-sl(bottom, 140, 0.5);
        //z-index: 20; //above logohome
    }

    @include breakpoint(medium) {
        .text {
            width:75%; 
        }
    }
}

//typo
.bl-herohome {
    .text {
        @include font-size-pt(72*0.5, 72);
        font-weight: 700;
        line-height:math.div(94, 72); 
    }
    .surtitle {
        @include font-size-pt(40*0.5, 40);
        font-weight: 700;
        color:$gray02;

        @include breakpoint(medium down) {
            color:$black;
        }
    }
}