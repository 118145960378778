footer {
  text-align: center;
  margin-top: 8em;
  margin-bottom: 4em;
}

//typo
footer {
  @include font-size-pt(18, 18);
  font-weight: 500;
  line-height: math.div(24, 18);
}