header {
  .ctrlogo {
    @include rem-calc-sl(width, 370, 0.5);
    img {
      @include w100bl;
    }
  }
}

body.ishome1 header {
  position: absolute;
  @include rem-calc-sl(top, 46, 0.5);
  left:0;
  width: 100%;
  z-index: 10;
}

body.ishome0 header {
  @include rem-calc-sl(margin-top, 46, 0.5);
  @include rem-calc-sl(margin-bottom, 160, 0.5);
}
