.bl-2img {
    @include breakpoint(large) {
        @include grid12;

        /*
        .ctrimg1, .ctrimg2 {
            grid-row:1;
        }
        */
        &.layoutsl {
            .ctrimg1 {
                grid-column:2 / span 4;
            }
            .ctrimg2 {
                grid-column:7 / span 5;
            }
        }
        &.layoutls {
            .ctrimg1 {
                grid-column:2 / span 5;
            }
            .ctrimg2 {
                grid-column:8 / span 4;
            }
        }

        .ctrimg2 {
            margin-top:5em;
        }       
    }
    @include breakpoint(medium down) {
        @include grid;
        grid-template-columns: repeat(6, 1fr);
        row-gap:rem-calc(50);
        .ctrimg1 {
            grid-column:1 / span 5;
        }
        .ctrimg2 {
            grid-column:2 / span 5;
        }
    }

    .bigimg {
        @include w100bl;
    }

    .ctrinfo {
        @include rem-calc-sl(margin-top, 40, math.div(25, 40));
    }
    .ctrbuttons {
        @include rem-calc-sl(margin-top, 32, math.div(17, 40));
    }
}