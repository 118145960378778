.buttonbar {}

.button {
  display: block;
  width: fit-content;
  padding: 0.75em 1.5em;
  color: $buttonfg;
  //@include borderradius2;
  background-color: $buttonbg;
  
  cursor: pointer;

  border:2px solid $black;
  border-radius: 999px;
}
button.button {
  //remove default <button> styling
  //border:none;
  outline: none;
}

.button {
  transition: background-color 0.2s;

  &:hover {
    color: $buttonfg-hover;
    background-color: $buttonbg-hover;
  }
}

//typo
.button {
  @include font-size-pt(18, 18);
  font-weight: 700;
  line-height: math.div(26, 18);
}